import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Footer from './Footer';
import Modal from '@material-ui/core/Modal';
import Faq from './Faq';

const isNarrow = window.innerWidth < 600;
const modalHRatio = isNarrow ? 0.05 : 0.1;
const modalVRatio = isNarrow ? 0.05 : 0.1;

const useStyles = makeStyles (theme => ({
  root: {
    flexGrow: 0,
  },
  close: {
    color: 'white',
    right: 12,
    width: 32,
    border: 'none',
    height: 32,
    position: 'absolute',
    fontSize: 22,
    textAlign: 'center',
    fontWeight: 700,
    lineHeight: 1.5,
    borderRadius: 30,
    top: 5,
    background: '#19b063',
    cursor: 'pointer',
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  gridWhite: {
    textAlign: 'center',
    backgroundColor: '#fff',
    color: theme.palette.text.secondary,
    borderTop: '1px solid #ddd',
    borderBottom: '1px solid #ddd',
  },
  gridGrey: {
    textAlign: 'center',
    backgroundColor: '#f5f5f5',
    color: theme.palette.text.secondary,
  },
  greyGreen: {
    backgroundColor: theme.palette.greyGreen.main,
    paddingBottom: 120,
    textAlign: 'center',
  },
  greyTest: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  modalContent: {
    position: 'absolute',
    top: Math.ceil (window.innerHeight * modalHRatio),
    left: Math.ceil (window.innerWidth * modalVRatio),
    width: Math.ceil (window.innerWidth * (1 - modalHRatio * 2)),
    height: Math.ceil (window.innerHeight * (1 - modalHRatio * 2)),
    backgroundColor: 'white',
    borderRadius: 20,
    paddingTop: 20,
  },
  footer: {
    backgroundColor: theme.palette.greyBg.main,
    textAlign: 'center',
    padding: '50px 0',
  },
}));

export default function Layout({children}) {
  const classes = useStyles ();
  const [open, setOpen] = useState (false);

  const handleOpen = () => {
    setOpen (true);
  };

  const handleClose = () => {
    setOpen (false);
  };

  return (
    <React.Fragment>
      <Container maxWidth="xl" style={{padding: 0}} disableGutters>
        <Grid container direction="column">
          {children.map ((el, i) => {
            //const _cl = i % 2 ? classes.gridWhite : classes.gridGrey;
            //const _className = classes[el.props.specialClass] || _cl;
            const _padding = typeof el.props.padding === 'string' ||
              el.props.padding > -1
              ? el.props.padding
              : 10;

            return (
              <Grid item xs={12} key={i} style={{padding: _padding}}>
                {el}
              </Grid>
            );
          })}
        </Grid>
      </Container>

      <Modal
        open={open}
        className="uic__overlay"
        container={() => document.querySelector ('body')}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Container
          className={classes.modalContent}
          disableGutters
          style={{overflowY: 'auto'}}
        >
          <Faq />
          <div className={classes.close} onClick={handleClose}>
            <span>X</span>
          </div>
        </Container>
      </Modal>
    </React.Fragment>
  );
}
