import React, {useState} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import Modal from '@material-ui/core/Modal';
import RadioGroup from '@material-ui/core/RadioGroup';
import useGlobal from '../../store';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import {useForm, Controller} from 'react-hook-form';
import base64 from 'base-64';
import axios from 'axios';
import ModalReclamo from './ModalReclamo';
import ModalShare from './ModalShare';

const useStyles = makeStyles (theme => {
  return {
    text: {
      backgroundColor: 'white',
    },
    container: {padding: '0 6px', paddingTop: 20},
    formErr: {textAlign: 'left', color: 'red', paddingBottom: 4},
    imgQm: {textAlign: 'right', marginTop: -40, position: 'relative'},
    radioTitle: {
      textAlign: 'left',
      fontSize: 18,
      fontWeight: 600,
      marginLeft: 8,
      color: '#111',
    },
    radioLabel: {
      textAlign: 'left!important',
      fontWeight: 'bold!important',
      color: '#111',
      fontSize: 18,
    },
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing (2, 4, 3),
    },
  };
});

export default function Input({onSuccess, onError}) {
  const _isMobile = window.innerWidth < 769;
  const [globalState, globalActions] = useGlobal ();
  const {
    control,
    handleSubmit,
    getValues,
    register,
    errors,
    formState,
    trigger,
  } = useForm ({
    mode: 'onBlur',
  });

  const {isValid} = formState;
  const [isSending, setIsSending] = useState (false);
  const classes = useStyles ();
  const [open1, setOpen1] = useState (false);
  const [open2, setOpen2] = useState (false);
  const [openPub, setOpenPub] = useState (false);

  const handleOpen1 = () => {
    setOpen1 (true);
    document.querySelector ('body').classList.add ('uic-hint__open');
  };

  const handleClose = () => {
    setOpen1 (false);
    setOpen2 (false);
    document.querySelector ('body').classList.remove ('uic-hint__open');
  };

  const handleOpenPub = () => {
    setOpenPub (true);
    document.querySelector ('body').classList.add ('uic-hint__open');
  };

  const handlePubChange = e => {
    if (e.target.value === 'Si') {
      setOpenPub (true);
    }

    if (e.target.value === 'No') {
      setOpenPub (false);
    }
  };

  const handleOpen2 = () => {
    setOpen2 (true);
  };

  const onSubmit = data => {
    const {id, az, category, metaCat} = globalState.azienda;

    //return true;
    setIsSending (true);

    const fkCategory = category && category.id ? category.id : 7;
    const formId = metaCat ? metaCat.id : id;
    const formName = metaCat ? metaCat.az : az;
    const reclamo = data.reclamo + `\n\n# RIF AZIENDA: ${az} #`;

    console.log ('mc4wp-subscribe', data.mc4wpsubscribe === 'true' ? '1' : '0');
    // Build formData object.
    let formData = new FormData ();
    formData.append ('email', data.email);
    formData.append ('azienda', formName ? formName : 'Sportello Generico');
    formData.append ('form', formName ? formName : 'Sportello Generico');
    formData.append ('azienda_id', formId ? formId : 1);
    formData.append ('categoria_id', fkCategory ? fkCategory : 7);
    formData.append (
      'categoria',
      fkCategory ? fkCategory : 'Sportello Generico'
    );
    formData.append ('nome', data.nome);
    formData.append ('cognome', data.cognome);
    formData.append ('mc4wp-nome', data.nome);
    formData.append ('mc4wp-cognome', data.cognome);
    formData.append ('mc4wp-form', 'Reclami');
    formData.append (
      'mc4wp-campagna',
      globalState.azienda.isCat
        ? globalState.azienda.ct
        : globalState.azienda.category.ct
    );
    formData.append ('telefono', data.telefono);
    formData.append ('fk_provincia', data.provincia);
    formData.append ('problema', reclamo);
    formData.append ('muro', data.pubblica === 'Si' ? '1' : '0');
    formData.append ('comitato', globalState.comitato || 'you-tool');
    formData.append ('fk_category', fkCategory ? fkCategory : 7);
    formData.append ('form_id', formId ? formId : 1);
    formData.append ('consenso', '1');
    if (data.mc4wpsubscribe === 'true') {
      formData.append ('mc4wp-subscribe', '1');
    }

    let headers = new Headers ();
    // eslint-disable-next-line
    headers.set (
      'Authorization',
      'Basic ' + base64.encode ('kf' + ':' + '2020')
    );

    axios
      .post (
        `https://${window.location.hostname}/wp-admin/admin-ajax.php?action=YNCDB_wall&pag=saveform`,
        formData,
        {headers}
      )
      .then (resp => {
        setIsSending (false);
        const idReclamo = resp.data.id;
        globalActions.setIdReclamo (idReclamo);
        globalActions.setFormData (data);
        document.querySelector ('body').classList.add ('uic-form__result-ok');
        onSuccess ();
        if (window.dataLayer && Array.isArray (window.dataLayer)) {
          window.dataLayer.push ({
            sportello: az,
            event: 'sportelloCompilato',
          });
        }
      })
      .catch (e => {
        setIsSending (false);
        console.log (e);
        document.querySelector ('body').classList.add ('uic-form__result-ko');
        onError ();
      });
  };

  const onStep = () => {
    const _formValues = getValues ();
    const {nome, cognome, email, reclamo} = _formValues;

    if (globalState.formStep === 1 && reclamo === '') {
      return false;
    }

    if (
      globalState.formStep === 2 &&
      (nome === '' || cognome === '' || email === '')
    ) {
      return false;
    }

    if (!isValid) {
      console.log (errors);
      if (globalState.formStep === 1 && errors.hasOwnProperty ('reclamo'))
        return false;

      if (
        globalState.formStep === 2 &&
        (errors.hasOwnProperty ('nome') ||
          errors.hasOwnProperty ('cognome') ||
          errors.hasOwnProperty ('telefono') ||
          errors.hasOwnProperty ('email') ||
          errors.hasOwnProperty ('provincia'))
      )
        return false;
    }

    if (globalState.formStep < 3) {
      globalActions.setFormStep (globalState.formStep + 1);
    } else {
      if (errors.hasOwnProperty ('reclamo')) {
        globalActions.setFormStep (1);
        return;
      }

      if (
        errors.hasOwnProperty ('nome') ||
        errors.hasOwnProperty ('cognome') ||
        errors.hasOwnProperty ('telefono') ||
        errors.hasOwnProperty ('email') ||
        errors.hasOwnProperty ('provincia')
      ) {
        globalActions.setFormStep (2);
        return;
      }

      const fnSubmit = handleSubmit (onSubmit);
      fnSubmit ();
    }
  };

  function getSlug () {
    console.log (
      '----->',
      (globalState && globalState.azienda && globalState.azienda.slug) || ''
    );
    return (
      (globalState && globalState.azienda && globalState.azienda.slug) || ''
    );
    /*
    const path = new URL (window.location.href).pathname;
    let regex;

    if (path.includes ('bacheca')) {
      regex = /\/reclami\/bacheca\/([a-z-]*)\//;
    }

    if (path.includes ('news')) {
      regex = /\/reclami\/bacheca\/([a-z-]*)\//;
    } 

    const regex = /\/reclami\/(.*)\//;
    return regex.exec (path)[1];
    */
  }

  return (
    <React.Fragment>
      <form id="form_reclami" class={getSlug ()}>
        <Container disableGutters className={classes.container}>
          <Container maxWidth="sm" disableGutters>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{display: globalState.formStep === 1 ? 'block' : 'none'}}
              >
                <TextField
                  inputRef={register ({minLength: 7, required: true})}
                  //onFocus={_isMobile ? null : handleOpen1}
                  onBlur={handleClose}
                  name="reclamo"
                  fullWidth
                  className={classes.text}
                  id="reclamo"
                  label="Scrivi qui la tua storia*"
                  multiline
                  rows={10}
                  defaultValue=""
                  variant="outlined"
                />
                <div
                  className={classes.imgQm}
                  id="unc-helpbtn__container"
                  onClick={handleOpen1}
                >
                  <p>
                    dubbi sulla compilazione
                    <span className="unc-icon unc-icon-question" />
                  </p>

                </div>
                {errors.reclamo &&
                  <div className={classes.formErr}>
                    Campo obbligatorio o formato non corretto
                  </div>}
              </Grid>
              <Grid
                item
                xs={12}
                style={{display: globalState.formStep === 2 ? 'block' : 'none'}}
              >
                <TextField
                  inputRef={register ({
                    required: true,
                    minLength: 2,
                    maxLength: 20,
                  })}
                  name="nome"
                  fullWidth
                  className={classes.text}
                  id="nome"
                  label="Il tuo Nome*"
                  defaultValue=""
                  variant="outlined"
                />
                {errors.nome &&
                  <div className={classes.formErr}>
                    Campo obbligatorio o formato non corretto
                  </div>}
              </Grid>

              <Grid
                item
                xs={12}
                style={{display: globalState.formStep === 2 ? 'block' : 'none'}}
              >
                <TextField
                  inputRef={register ({
                    required: true,
                    minLength: 2,
                    maxLength: 20,
                  })}
                  name="cognome"
                  fullWidth
                  className={classes.text}
                  id="cognome"
                  label="Il tuo Cognome*"
                  defaultValue=""
                  variant="outlined"
                />
                {errors.cognome &&
                  <div className={classes.formErr}>
                    Campo obbligatorio o formato non corretto
                  </div>}
              </Grid>
              <Grid
                item
                xs={12}
                style={{display: globalState.formStep === 2 ? 'block' : 'none'}}
              >
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.text}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Provincia*
                  </InputLabel>

                  <Controller
                    name="provincia"
                    control={control}
                    rules={{validate: value => value > 0}}
                    defaultValue="00"
                    as={
                      <Select native value="" style={{width: '100%'}}>
                        <option value="" />
                        <option value="84">Agrigento</option>
                        <option value="6">Alessandria</option>
                        <option value="42">Ancona</option>
                        <option value="7">Aosta</option>
                        <option value="51">Arezzo</option>
                        <option value="44">Ascoli Piceno</option>
                        <option value="5">Asti</option>
                        <option value="64">Avellino</option>
                        <option value="72">Bari</option>
                        <option value="110">Barletta-Andria-Trani</option>
                        <option value="25">Belluno</option>
                        <option value="62">Benevento</option>
                        <option value="16">Bergamo</option>
                        <option value="96">Biella</option>
                        <option value="37">Bologna</option>
                        <option value="21">Bolzano/Bozen</option>
                        <option value="17">Brescia</option>
                        <option value="74">Brindisi</option>
                        <option value="92">Cagliari</option>
                        <option value="85">Caltanissetta</option>
                        <option value="70">Campobasso</option>
                        <option value="107">Carbonia-Iglesias</option>
                        <option value="61">Caserta</option>
                        <option value="87">Catania</option>
                        <option value="79">Catanzaro</option>
                        <option value="69">Chieti</option>
                        <option value="13">Como</option>
                        <option value="78">Cosenza</option>
                        <option value="19">Cremona</option>
                        <option value="101">Crotone</option>
                        <option value="4">Cuneo</option>
                        <option value="86">Enna</option>
                        <option value="109">Fermo</option>
                        <option value="38">Ferrara</option>
                        <option value="48">Firenze</option>
                        <option value="71">Foggia</option>
                        <option value="40">Forlì-Cesena</option>
                        <option value="60">Frosinone</option>
                        <option value="10">Genova</option>
                        <option value="31">Gorizia</option>
                        <option value="53">Grosseto</option>
                        <option value="8">Imperia</option>
                        <option value="94">Isernia</option>
                        <option value="66">L'Aquila</option>
                        <option value="11">La Spezia</option>
                        <option value="59">Latina</option>
                        <option value="75">Lecce</option>
                        <option value="97">Lecco</option>
                        <option value="49">Livorno</option>
                        <option value="98">Lodi</option>
                        <option value="46">Lucca</option>
                        <option value="43">Macerata</option>
                        <option value="20">Mantova</option>
                        <option value="45">Massa-Carrara</option>
                        <option value="77">Matera</option>
                        <option value="106">Medio Campidano</option>
                        <option value="83">Messina</option>
                        <option value="15">Milano</option>
                        <option value="36">Modena</option>
                        <option value="108">Monza e della Brianza</option>
                        <option value="63">Napoli</option>
                        <option value="3">Novara</option>
                        <option value="91">Nuoro</option>
                        <option value="105">Ogliastra</option>
                        <option value="104">Olbia-Tempio</option>
                        <option value="95">Oristano</option>
                        <option value="28">Padova</option>
                        <option value="82">Palermo</option>
                        <option value="34">Parma</option>
                        <option value="18">Pavia</option>
                        <option value="54">Perugia</option>
                        <option value="41">Pesaro e Urbino</option>
                        <option value="68">Pescara</option>
                        <option value="33">Piacenza</option>
                        <option value="50">Pisa</option>
                        <option value="47">Pistoia</option>
                        <option value="93">Pordenone</option>
                        <option value="76">Potenza</option>
                        <option value="100">Prato</option>
                        <option value="88">Ragusa</option>
                        <option value="39">Ravenna</option>
                        <option value="80">Reggio di Calabria</option>
                        <option value="35">Reggio nell'Emilia</option>
                        <option value="57">Rieti</option>
                        <option value="99">Rimini</option>
                        <option value="58">Roma</option>
                        <option value="29">Rovigo</option>
                        <option value="65">Salerno</option>
                        <option value="90">Sassari</option>
                        <option value="9">Savona</option>
                        <option value="52">Siena</option>
                        <option value="89">Siracusa</option>
                        <option value="14">Sondrio</option>
                        <option value="73">Taranto</option>
                        <option value="67">Teramo</option>
                        <option value="55">Terni</option>
                        <option value="1">Torino</option>
                        <option value="81">Trapani</option>
                        <option value="22">Trento</option>
                        <option value="26">Treviso</option>
                        <option value="32">Trieste</option>
                        <option value="30">Udine</option>
                        <option value="12">Varese</option>
                        <option value="27">Venezia</option>
                        <option value="103">Verbano-Cusio-Ossola</option>
                        <option value="2">Vercelli</option>
                        <option value="23">Verona</option>
                        <option value="102">Vibo Valentia</option>
                        <option value="24">Vicenza</option>
                        <option value="56">Viterbo</option>
                      </Select>
                    }
                  />
                </FormControl>
                {errors.provincia &&
                  <div className={classes.formErr}>
                    Campo obbligatorio o formato non corretto
                  </div>}
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  marginTop: 20,
                  display: globalState.formStep === 2 ? 'block' : 'none',
                }}
              >
                <TextField
                  inputRef={register ({
                    required: true,
                    pattern: /^\S+@\S+\.\S+$/,
                  })}
                  name="email"
                  fullWidth
                  className={classes.text}
                  id="email"
                  label="E-mail*"
                  defaultValue=""
                  variant="outlined"
                />
                {errors.email &&
                  <div className={classes.formErr}>
                    Indirizzo e-mail non valido
                  </div>}
              </Grid>
              <Grid
                item
                xs={12}
                style={{display: globalState.formStep === 2 ? 'block' : 'none'}}
              >
                <TextField
                  inputRef={register ({
                    required: false,
                    pattern: /^[03+][\d.-]{6,15}$/,
                    minLength: 7,
                    maxLength: 20,
                  })}
                  name="telefono"
                  fullWidth
                  className={classes.text}
                  id="telefono"
                  label="Telefono"
                  defaultValue=""
                  variant="outlined"
                />
                {errors.telefono &&
                  <div className={classes.formErr}>
                    Formato non corretto (solo numeri)
                  </div>}
              </Grid>
            </Grid>
          </Container>
        </Container>
        <Container
          maxWidth="xl"
          disableGutters
          style={{
            backgroundColor: 'white',
          }}
        >
          <Container
            disableGutters
            className={classes.container}
            maxWidth="sm"
            style={{
              marginTop: 40,
            }}
          >
            <Grid container spacing={3} className={'last-step'}>
              <Grid
                item
                xs={12}
                className={classes.radioTitle}
                style={{display: globalState.formStep === 3 ? 'block' : 'none'}}
              >
                <div>
                  Aiuta gli altri consumatori:
                  <br /> Pubblica il tuo reclamo in bacheca!
                  <br />
                  <span style={{fontSize: 16, color: '#333', fontWeight: 400}}>
                    <i>(Il tuo cognome non verrà pubblicato)</i>
                  </span>
                </div>
                <div
                  className={classes.imgQm}
                  style={{width: '100%', top: -10}}
                  onClick={handleOpen2}
                >
                  <img
                    alt=""
                    src="https://goat.keyformat.it/static/uip/media/qm-1.png"
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: 'left',
                  marginLeft: 10,
                  display: globalState.formStep === 3 ? 'block' : 'none',
                }}
              >
                <FormControl component="fieldset">
                  <Controller
                    name="pubblica"
                    rules={{
                      validate: value => value !== '',
                    }}
                    defaultValue=""
                    control={control}
                    as={
                      <RadioGroup
                        aria-label="pubblica"
                        name="pubblico"
                        value="Si"
                        onClick={handlePubChange}
                      >
                        <FormControlLabel
                          value="Si"
                          control={<Radio />}
                          classes={{
                            label: classes.radioLabel, // class name, e.g. `classes-nesting-label-x`
                          }}
                          label="Sì, consenti a tutti di vederla"
                        />
                        {openPub &&
                          <div
                            style={{
                              fontSize: 14,
                              lineHeight: '14px',
                              color: 'white',
                              background: '#a30a0a',
                              borderRadius: 12,
                              padding: 20,
                              fontWeight: 'bold',
                            }}
                          >
                            Se scegli di pubblicare il reclamo fai attenzione a non inserire nel testo i tuoi dati personali.
                            {' '}
                            <br />
                            Il reclamo sarà visibile sul nostro sito ma anche su Google!
                          </div>}

                        <FormControlLabel
                          classes={{
                            label: classes.radioLabel, // class name, e.g. `classes-nesting-label-x`
                          }}
                          value="No"
                          control={<Radio />}
                          label="No, tienila riservata"
                        />
                      </RadioGroup>
                    }
                  />
                </FormControl>
                {errors.pubblica &&
                  <div className={classes.formErr}>
                    Indica una scelta.
                  </div>}
                <Typography variant="subtitle1">
                  <strong>
                    <br />
                    UNC non è in alcun modo responsabile per le dichiarazioni espresse da altri sul sito consumatori.it.
                  </strong>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: 'left',
                  marginLeft: 10,
                  display: 'inline-flex',
                  display: globalState.formStep === 3 ? 'block' : 'none',
                }}
              >
                <Controller
                  defaultValue="false"
                  name="consent"
                  control={control}
                  rules={{
                    validate: value => value === 'true',
                  }}
                  as={
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="consent"
                          value="true"
                          color="primary"
                          onClick={e => {
                            e.target.value = e.target.checked;
                          }}
                        />
                      }
                      label=""
                      value="true"
                    />
                  }
                />

                <Typography variant="subtitle1">
                  {errors.consent &&
                    <div className={classes.formErr}>Esprimo il consenso</div>}
                  Confermo di aver preso visione dell'
                  <a href="/privacy-policy/" target="_blank">
                    informativa sulla privacy
                  </a>
                  {' '}
                  e autorizzo il trattamento dei miei dati personali al solo fine di rispondere alla mia richiesta*
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} className={'last-step'}>
              <Grid
                item
                xs={12}
                className={classes.radioTitle}
                style={{
                  display: globalState.formStep === 3 ? 'block' : 'none',
                  marginTop: -50,
                }}
              />
              <Grid
                item
                xs={12}
                style={{
                  textAlign: 'left',
                  marginLeft: 10,
                  display: globalState.formStep === 3 ? 'block' : 'none',
                }}
              />
              <Grid
                item
                xs={12}
                style={{
                  textAlign: 'left',
                  marginLeft: 11,
                  marginTop: -40,
                  display: 'inline-flex',
                  display: globalState.formStep === 3 ? 'block' : 'none',
                }}
              >
                <Controller
                  defaultValue="false"
                  name="mc4wpsubscribe"
                  control={control}
                  as={
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="mc4wpsubscribe"
                          value="true"
                          color="primary"
                          onClick={e => {
                            e.target.value = e.target.checked;
                          }}
                        />
                      }
                      label=""
                      value="true"
                    />
                  }
                />

                <Typography variant="subtitle1">
                  Confermo l'iscrizione alla newsletter di UNC per ricevere periodicamente informazioni relative a iniziative, attività e promozioni.
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid className={'uic-form__stepcount'} container>
                  <Grid className={'uip-form__count-cont'} item xs={4}>
                    <div
                      onClick={() => globalActions.setFormStep (1)}
                      className={(() =>
                        globalState.formStep === 1
                          ? 'uip-form__count-el active'
                          : 'uip-form__count-el') ()}
                    >
                      1
                    </div>
                  </Grid>
                  <Grid className={'uip-form__count-cont'} item xs={4}>
                    <div
                      onClick={() =>
                        globalActions.setFormStep > 2 &&
                        globalActions.setFormStep (2)}
                      className={(() =>
                        globalState.formStep === 2
                          ? 'uip-form__count-el active'
                          : 'uip-form__count-el') ()}
                    >
                      2
                    </div>
                  </Grid>
                  <Grid className={'uip-form__count-cont'} item xs={4}>
                    <div
                      onClick={() =>
                        globalActions.setFormStep > 3 &&
                        globalActions.setFormStep (3)}
                      className={(() =>
                        globalState.formStep === 3
                          ? 'uip-form__count-el active'
                          : 'uip-form__count-el') ()}
                    >
                      3
                    </div>
                  </Grid>
                </Grid>
                {isSending && <CircularProgress />}
              </Grid>
              <Grid item xs={6}>

                {!isSending &&
                  <Button
                    style={{marginTop: 30, marginBottom: 30}}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={onStep}
                  >
                    {globalState.formStep === 3 && <span>INVIA RECLAMO!</span>}
                    {globalState.formStep < 3 && <span>AVANTI</span>}
                  </Button>}
              </Grid>
            </Grid>
          </Container>
        </Container>
      </form>
      <Modal
        open={open1}
        id="uic-modal__container"
        className={classes.modal}
        container={() => document.getElementsByTagName ('body')[0]}
        onClose={handleClose}
        disableRestoreFocus
        hideBackdrop
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalReclamo handleClose={handleClose} />
      </Modal>
    </React.Fragment>
  );
}
