import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import useGlobal from "../../store";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "white",
  },
}));

export default function Head() {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="flex-start"
      style={{ paddingBottom: 8 }}
    >
      <Grid item xs={2} style={{ textAlign: "left" }}>
        {(2 === globalState.step || 3 === globalState.step) && (
          <img
            src="https://goat.keyformat.it/static/uip/media/back-active-mini.png"
            alt="Indietro"
            className="unc-ui__withpointer"
            onClick={() => globalActions.setStep(globalState.step - 1)}
          />
        )}
      </Grid>

      <Grid item xs={8} style={{ paddingTop: 6 }}>
        {1 === globalState.step && (
          <img
            alt=""
            src="https://goat.keyformat.it/static/uip/media/step-bar-1.png"
          />
        )}
        {2 === globalState.step && (
          <img
            alt=""
            src="https://goat.keyformat.it/static/uip/media/step-bar-2.png"
          />
        )}
        {3 === globalState.step && (
          <img
            alt=""
            src="https://goat.keyformat.it/static/uip/media/step-bar-3.png"
          />
        )}
        {4 === globalState.step && (
          <img
            alt=""
            src="https://goat.keyformat.it/static/uip/media/step-bar-4.png"
          />
        )}
      </Grid>

      <Grid item xs={2} style={{ textAlign: "right" }}>
        {4 === globalState.step && (
          <img
            alt=""
            src="https://goat.keyformat.it/static/uip/media/ok-v-on.png"
          />
        )}
      </Grid>

      {1 === globalState.step && (
        <Grid item xs={12}>
          <img
            alt=""
            src="https://goat.keyformat.it/static/uip/media/sadman-1.png"
            width="90"
          />
        </Grid>
      )}

      <Grid item xs={12}>
        {1 === globalState.step && (
          <Typography variant="h2" className={classes.title}>
            Hai un problema da risolvere?
          </Typography>
        )}

        {2 === globalState.step && (
          <Container disableGutters style={{ marginTop: 20, marginBottom: 6 }}>
            <Typography variant="h2" className={classes.title}>
              Inizia subito...
            </Typography>
            <Typography variant="h4" className={classes.title}>
              qual è l'azienda che ti dà problemi?
            </Typography>
          </Container>
        )}

        {3 === globalState.step && (
          <Container disableGutters style={{ marginTop: 20, marginBottom: 6 }}>
            <Typography variant="h2" className={classes.title}>
              Ci sei quasi:
            </Typography>
            <Typography variant="h4" className={classes.title}>
              è il momento di raccontarci il tuo reclamo.
            </Typography>
          </Container>
        )}

        {4 === globalState.step && (
          <Container disableGutters style={{ marginTop: 20, marginBottom: 6 }}>
            <Typography variant="h2" className={classes.title}>
              Reclamo inviato!
            </Typography>
          </Container>
        )}
      </Grid>
    </Grid>
  );
}
