import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Results from './Results';
import useGlobal from '../../store';
import {makeStyles} from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import MostSearched from '../commons/MostSearched';
import SearchIcon from '@material-ui/icons/Search';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles (theme => {
  return {
    root: {
      marginTop: 20,
    },
    mostSearched: {
      marginTop: 15,
    },
    input: {
      border: `4px solid`,
      borderColor: theme.palette.primary.main,
      backgroundColor: 'white',
      borderRadius: 50,
      height: 60,
      paddingLeft: 20,
      fontSize: 22,
      color: '#444',
    },
  };
});

export default function Search({type}) {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal ();
  const classes = useStyles ();

  const keyPress = e => {
    if (e.charCode === 13) {
      if (globalState.results && globalState.results.length > 0) {
        const az = globalState.results[0].item;
        if (
          !az.az
            .toLowerCase ()
            .includes (globalState.searchInput.toLowerCase ())
        ) {
          return;
        }

        globalActions.setAzienda (az);
        globalActions.setStep (3);
      }
    }
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Container maxWidth="sm" disableGutters>
          <InputBase
            onKeyPress={keyPress}
            className={classes.input}
            id="search__input"
            label="Azienda o settore"
            variant="outlined"
            placeholder="Reclama: scrivi qui il nome dell'azienda"
            value={globalState.searchInput}
            fullWidth
            startAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="Cerca" edge="start">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            onChange={e => globalActions.setSearchInput (e.target.value)}
          />
        </Container>
      </Grid>
      {globalState.searchInput.length < 2 &&
        type !== 'search-mini' &&
        <Grid
          item
          xs={12}
          style={{marginBottom: 10}}
          className="MuiFlexContainer"
        >
          <Typography variant="body1" className="MuiTransparentBg">
            Non ricordi il nome dell'azienda o il settore?
          </Typography>
          <Chip
            label={'Clicca qui'}
            component="a"
            onClick={() => globalActions.setEmptyResults ()}
            clickable
            className={`MuiCategoryAgencyBtn uic-chip`}
          />
        </Grid>}

      {globalState.searchInput.length > 1 &&
        <Grid item xs={12}>
          <Results type={type} />
        </Grid>}
      {globalState.results.length === 0 &&
        globalState.searchInput.length < 2 &&
        type !== 'search-mini' &&
        <Grid item xs={12} className={classes.mostSearched}>
          <MostSearched maxItems={10} extraspace={10} />
        </Grid>}
    </Grid>
  );
}
