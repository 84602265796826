import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import useGlobal from '../../store';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles (theme => {
  return {
    root: {
      width: '10vw',
      minWidth: 100,
      height: '10vw',
      minHeight: 100,
      borderRadius: 1000,
      backgroundColor: theme.palette.primary.main,
    },
    label: {
      color: 'white',
      fontWeight: 600,
      lineHeight: '18px !important',
    },
    chip: {
      borderRadius: 20,
      height: 44,
      padding: '-2px 3px!important',
      lineHeight: 1,
      fontSize: 16,
      fontWeight: 600,
      margin: '7px 3px',
      color: 'white',
    },
    az: {
      backgroundColor: theme.palette.primary.main,
    },
    se: {
      backgroundColor: theme.palette.secondary.main,
    },
  };
});

export default function Item({azienda, renderAsChip, style, label}) {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal ();
  const classes = useStyles ();

  const setAziendaAndGotoForm = function _setAzGoForm () {
    globalActions.setAzienda (azienda);
    //lobalActions.setStep(3);
    const _url = new URL (window.location.href);
    const _slug = azienda.slug || azienda.az.toLowerCase ();
    window.location.href = `${_url.protocol}//${_url.hostname}/reclami/${_slug}`;
  };

  const secClass = azienda.isCat ? 'se' : 'az';

  return (
    <React.Fragment>
      {renderAsChip &&
        <Chip
          label={label || azienda.ct || azienda.se || azienda.az}
          component="a"
          onClick={setAziendaAndGotoForm}
          clickable
          style={style}
          className={`MuiCategoryAgencyBtn uic-chip uic-chip-${secClass} ${classes.chip} ${classes[secClass]}`}
        />}
      {!renderAsChip &&
        <Container className={classes.root} disableGutters>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{height: '100%'}}
          >
            <Typography
              className={classes.label}
              variant="body1"
              onClick={setAziendaAndGotoForm}
              style={{color: 'white'}}
            >
              {label || azienda.ct || azienda.se || azienda.az}
            </Typography>
          </Grid>
        </Container>}
    </React.Fragment>
  );
}
