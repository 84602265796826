import React from 'react';
import Container from '@material-ui/core/Container';
import Item from '../commons/Item';
import Settori from './Settori';
import Typography from '@material-ui/core/Typography';
import useGlobal from '../../store';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles (theme => {
  return {
    root: {
      marginTop: -40,
      paddingBottom: 28,
      paddingTop: 40,
      backgroundColor: theme.palette.greyBg.main,
      borderRadius: 15,
    },
  };
});

export default function Results({type}) {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal ();
  const classes = useStyles ();

  return (
    <Container
      disableGutters
      maxWidth="sm"
      className="MuiContainer-root uic-search__res-container"
    >
      {globalState.results &&
        globalState.results.length > 0 &&
        <Typography variant="body1">Risultati</Typography>}

      {!globalState.results ||
        (globalState.results.length === 0 &&
          <Grid container spacing={2}>
            <Settori type={type} />
          </Grid>)}
      {globalState.results &&
        globalState.results.map ((res, i) => (
          <Item renderAsChip azienda={res.item} key={i} />
        ))}
    </Container>
  );
}
