import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles (theme => ({
  root: {
    padding: '10px 0',
  },
  title: {
    color: theme.palette.primary.main,
    paddingBottom: 15,
  },
}));

export default function Message () {
  const classes = useStyles ();

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item xs={12}>

        <div class="gb-container gb-container-c08d16d9">
          <div class="gb-inside-container">
            <div
              class="gb-container gb-container-e3a7465e heading-blue-bg wrap-bradius-bshadow"
              style={{visibility: 'visible'}}
            >
              <div class="gb-inside-container">

                <div
                  class="wp-block-kadence-icon kt-svg-icons kt-svg-icons_43f91b-24 alignnone"
                  style={{textAlign: 'center'}}
                >
                  <div class="kt-svg-style-default kt-svg-icon-wrap kt-svg-item-0">
                    <div
                      style={{
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '00c993',
                      }}
                      class="kt-svg-icon kt-svg-icon-fe_checkCircle"
                    >
                      <svg
                        style={{
                          display: 'inline-block',
                          verticalAlign: 'middle',
                        }}
                        viewBox="0 0 24 24"
                        height="80"
                        width="80"
                        fill="none"
                        stroke="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        aria-hidden="true"
                      >
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                        <polyline points="22 4 12 14.01 9 11.01" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div
                  style={{height: 15}}
                  aria-hidden="true"
                  class="wp-block-spacer"
                />

                <h1 class="has-text-align-center">
                  Grazie, la tua segnalazione è stata inviata correttamente!
                </h1>

                <div class="wp-block-kadence-spacer aligncenter kt-block-spacer-_83ac85-41">
                  <div class="kt-block-spacer kt-block-spacer-halign-center">
                    <hr class="kt-divider" />
                  </div>
                </div>

                <p class="has-text-align-center mb0" style={{fontSize: 18}}>
                  Il tuo reclamo sarà esaminato dai nostri che ti
                  {' '}
                  <strong>contatteranno al più presto</strong>
                  {' '}
                  per fornirti assistenza personalizzata!
                </p>

              </div>
            </div>
          </div>
        </div>

        <div class="gb-container gb-container-34facd79">
          <div class="gb-inside-container">
            <div
              class="gb-container gb-container-a5c3f1dd wrap-bradius-bshadow pricing-container"
              style={{visibility: 'visible'}}
            >
              <div class="gb-inside-container">

                <h3 class="has-text-align-left mb0">
                  Per essere certi che tu riceva un riscontro ecco alcuni piccoli ma importanti consigli che potrebbero esserti utili:
                </h3>

                <div
                  style={{height: 40}}
                  aria-hidden="true"
                  class="wp-block-spacer"
                />

                <ul>
                  <li>
                    <strong>Aspetta almeno un paio di giorni</strong>
                    : è questo il tempo massimo che impiegano i consulenti a rispondere alle segnalazioni.
                  </li>
                  <li>
                    <strong>
                      Controlla lo&nbsp;
                      <em>spam</em>
                      &nbsp;(e la scheda “Promozioni” se stai usando Gmail, o la scheda “Altra” se usi Microsoft Outlook)
                    </strong>
                    : può succedere che alcuni&nbsp;
                    <em>client</em>
                    &nbsp;di posta spostino la prima comunicazione, questo non ti consentirebbe di poterla leggere.
                  </li>
                  <li>
                    <strong>Contattaci</strong>
                    : se, trascorsi un paio di giorni e verificato lo&nbsp;
                    <em>spam</em>
                    &nbsp;(e la scheda “Promozioni” se usi Gmail o la scheda “Altra” se usi Microsoft Outlook) non hai ancora ricevuto risposta segnalacelo scrivendo a
                    <a
                      href="mailto:info@consumatori.it"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      &nbsp;<strong>info@consumatori.it</strong>
                    </a>
                    <strong>
                      {' '}
                      &nbsp;indicando i dati forniti in fase di segnalazione, ti ricontatteremo tempestivamente!
                    </strong>
                  </li>
                </ul>

                <div
                  style={{height: 20}}
                  aria-hidden="true"
                  class="wp-block-spacer"
                />

                <p>
                  Qualora non fossi già un nostro associato, potrebbe esserti richiesto di
                  <strong> iscriverti all’Unione Nazionale Consumatori</strong>
                  : per informazione sui nostri servizi&nbsp;
                  <a href="/iscriviti-online/"><strong>clicca qui</strong></a>
                  <strong>.</strong>
                </p>

                <div
                  style={{height: 20}}
                  aria-hidden="true"
                  class="wp-block-spacer"
                />

                <p style={{fontSize: 14}}>
                  I tuoi dati saranno trattati nel rispetto del Codice Privacy, come indicato nella&nbsp;
                  <a href="/privacy-policy/">nostra informativa</a>
                  .
                </p>

              </div>
            </div>
          </div>
        </div>

        <div style={{height: 50}} aria-hidden="true" class="wp-block-spacer" />

        <div class="gb-button-wrapper gb-button-wrapper-5d659d09">

          <a class="gb-button gb-button-391e2e19 filled-cta" href="/">
            <span class="gb-icon">
              <svg
                aria-hidden="true"
                role="img"
                height="1em"
                width="1em"
                viewBox="0 0 256 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="currentColor"
                  d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
                />
              </svg>
            </span>
            <span class="gb-button-text">TORNA IN HOMEPAGE</span>
          </a>
        </div>
      </Grid>
    </Grid>
  );
}
