import superSearch from './supersearch';
import axios from 'axios';

export const setStep = (store, step) => {
  store.setState ({step});
};

export const setType = (store, type) => {
  store.setState ({type});
};

export const setFormStep = (store, formStep) => {
  const _rootEls = document.querySelectorAll ('.uic-tool-reclami__root');

  Array.from (_rootEls).forEach (_rootEl => {
    _rootEl.classList.remove (`unc-form__formstep-3`);
    _rootEl.classList.remove (`unc-form__formstep-2`);
    _rootEl.classList.remove (`unc-form__formstep-1`);

    _rootEl.classList.add (`unc-form__formstep-${formStep}`);
  });

  store.setState ({formStep});
};

export const setSearchInput = (store, searchInput) => {
  store.setState ({searchInput});

  store.setState ({results: superSearch (store.state.data, searchInput)});
};

export const setEmptyResults = store => {
  store.setState ({searchInput: '    '});

  store.setState ({results: []});
};

export const setIdAzienda = (store, idAzienda) => {
  store.setState ({idAzienda});
};

export const setIsCategory = (store, isCategory) => {
  store.setState ({isCategory});
};

export const setIsChatbot = (store, isChatbot) => {
  store.setState ({isChatbot});
};

export const setCampaign = (store, campaign) => {
  store.setState ({campaign});
};

export const setMedium = (store, medium) => {
  store.setState ({medium});
};

export const setSource = (store, source) => {
  store.setState ({source});
};

export const setAzienda = (store, azienda) => {
  store.setState ({azienda});
};

export const setIdReclamo = (store, idReclamo) => {
  store.setState ({idReclamo});
};

export const setFormData = (store, formData) => {
  store.setState ({formData});
};

export const setData = (store, data) => {
  store.setState ({data});
};

export const setComitato = (store, comitato) => {
  store.setState ({comitato});
};

function setStoreData (store, data) {
  const {recensioni, demo, settings, most_active_campaigns} = data;
  //const excludedIds = [35, 36, 37, 54, 49, 57, 59, 85];
  const excludedIds = [];

  const categorie =
    data.categorie &&
    data.categorie.map (cat => {
      return {
        isCat: true,
        category: {id: cat.id},
        metaCat: {id: cat.def.id, az: cat.def.az},
        id: cat.id,
        ct: cat.ct,
        ci: cat.def.id,
        az: cat.def.az,
        slug: cat.def.slug || null,
      };
    });

  const aziende =
    data.aziende &&
    data.aziende
      .filter (
        ({az, id}) =>
          !excludedIds.includes (id) &&
          //!az.includes ('Altr') &&
          !az.includes ('generico')
      )
      .map (az => {
        const catId = az.sp === 0
          ? data.aziende.filter (_az => _az.id === az.ci)[0].ci
          : az.ci;
        let catObj = categorie.filter (ct => ct.id === catId)[0];

        if (!catObj) {
          console.log ('data null for', az);
          catObj = {id: 0};
        }

        const fkCategory = 1 === az.sp ? az.ci : catObj.ci;
        const formId = 1 === az.sp ? az.id : catObj.id;
        const metaCat = !az.sp
          ? data.aziende.filter (_az => _az.id === az.ci)[0]
          : false;

        return {...az, category: catObj, fkCategory, formId, metaCat};
      });

  const _data = {
    aziende,
    categorie,
    recensioni,
    demo,
    settings,
    most_active_campaigns,
  };
  console.log ('*****', _data);
  store.state.data = _data;

  return _data;
}

export const getData = async store => {
  if (
    (store.state.data && store.state.data.length > 0) ||
    true === store.state.dataLoading
  ) {
    return false;
  }

  store.setState ({dataLoading: true});

  try {
    const endpoint = window.location.href.includes ('3000')
      ? '/data_v2.json'
      : 'https://goat.keyformat.it/static/uip/data/data_v2.json';

    const response = await axios.get (endpoint);
    let {data} = response;

    if (data && Array.isArray (data.aziende)) {
      setStoreData (store, data);
      store.setState ({dataLoading: false});

      console.log ('----------------->', store.state.idAzienda);

      if (store.state.isChatbot) {
        store.actions.setComitato ('you-chat');
      }

      if (store.state.medium === 'cpc') {
        if (store.state.source === 'dem') {
          store.actions.setComitato ('dem');
        } else {
          store.actions.setComitato ('ads');
        }
      } else if (store.state.medium === 'paid_social') {
        store.actions.setComitato ('meta');
      }

      if (store.state.idAzienda) {
        const azienda = store.state.data.aziende.filter (({id}) => {
          return id === parseInt (store.state.idAzienda, 10);
        });

        if (azienda && azienda.length === 1) {
          store.actions.setAzienda (azienda[0]);
          //store.actions.setComitato ('you-chat');
          store.actions.setStep (3);
        }

        console.log ('******-->', store);
      }

      if (store.state.isCategory) {
        console.log ('store.state.isCategory', store.state.isCategory);
        const _id = store.state.idAzienda;
        const categoria = store.state.data.categorie.filter (({id}) => {
          return id === parseInt (_id, 10);
        });

        if (categoria && categoria.length === 1) {
          store.actions.setAzienda (categoria[0]);
          //store.actions.setComitato ('you-chat');
          store.actions.setStep (3);
        }
      }
    }
  } catch (err) {
    console.log ('ERROR LOADING DATA!', err);
    setStoreData (store, []);
    store.setState ({dataLoading: false});
  }
};
