import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme ({
  breakpoints: {
    values: {
      xs: 0,
      sm: 720,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      'Arial',
    ].join (','),
  },
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      contained: {
        // Some CSS
        borderRadius: 30,
        border: 0,
        color: 'white!important',
        fontSize: 24,
        fontWeight: 700,
        height: 60,
        padding: '0 30px',
        boxShadow: '0',
      },
    },
    MuiTypography: {
      h2: {
        fontSize: 26,
        fontWeight: 700,
        color: '#00843c',
      },
      h3: {
        fontSize: 24,
        fontWeight: 700,
      },
      h4: {
        fontSize: 20,
        fontWeight: 700,
      },
      h6: {
        fontSize: 16,
        fontWeight: 500,
      },
      body1: {
        color: '#444',
        fontSize: 17,
        lineHeight: '130%',
      },
      body2: {
        color: '#444',
        fontSize: 18,
        lineHeight: '130%',
      },
      subtitle1: {
        color: '#444',
        fontSize: 15,
        lineHeight: '130%',
      },
      subtitle2: {
        color: '#444',
        fontSize: 16,
        lineHeight: '130%',
      },
    },
  },
  palette: {
    primary: {
      main: '#19b063',
    },
    secondary: {
      main: '#00843c',
    },
    greyBg: {},
    greyGreen: {
      main: '#6bab8a',
    },
    contrastThreshold: 6,
    tonalOffset: 0.2,
  },
  props: {
    MuiButtonBase: {
      disableRipple: false,
    },
  },
});

export default theme;
