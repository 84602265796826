import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

const els = document.querySelectorAll ('.uic-tool-reclami__root');

els.forEach (el => {
  const type = el.dataset.type ? el.dataset.type : 'form';
  const step = type === 'form' ? 3 : 2;
  const idAzienda = el.dataset.idAzienda
    ? parseInt (el.dataset.idAzienda)
    : false;

  const _params = new URLSearchParams (window.location.search);

  const isChatbot = _params.has ('cat');

  let campaign, medium, source;

  if (_params.get ('utm_campaign')) {
    campaign = _params.get ('utm_campaign');
    sessionStorage.setItem ('campaign', campaign);
  } else {
    if (sessionStorage.getItem ('campaign')) {
      campaign = sessionStorage.getItem ('campaign');
    }
  }

  if (_params.get ('utm_medium')) {
    medium = _params.get ('utm_medium');
    sessionStorage.setItem ('medium', medium);
  } else {
    if (sessionStorage.getItem ('medium')) {
      medium = sessionStorage.getItem ('medium');
    }
  }

  if (_params.get ('utm_source')) {
    source = _params.get ('utm_source');
    sessionStorage.setItem ('source', source);
  } else {
    if (sessionStorage.getItem ('source')) {
      source = sessionStorage.getItem ('source');
    }
  }

  const isCategory = el.dataset.isCat ? parseInt (el.dataset.isCat) : 0;

  //console.log ('type', type);

  ReactDOM.render (
    <React.StrictMode>
      <App
        type={type}
        step={step}
        idAzienda={idAzienda}
        isCategory={isCategory}
        isChatbot={isChatbot}
        campaign={campaign}
        medium={medium}
        source={source}
      />
    </React.StrictMode>,
    el
  );
});
