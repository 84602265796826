import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: "1.1rem ",
    fontWeight: 800,
    color: "#19b063",
  },
  content: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 400,
  },
  massiContainer: {
    overflow: "hidden",
    width: "100%",
    maxWidth: 140,
    transform: "rotate(-10deg)",
  },
}));

export default function Faq() {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Container className={classes.massiContainer}>
        <img
          alt=""
          src="https://goat.keyformat.it/static/uip/media/md-faq.png"
          width="100%"
        />
      </Container>
      <Typography
        variant="h2"
        style={{ textAlign: "center", paddingBottom: 30 }}
      >
        Risponde
        <br />
        Massimiliano Dona
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Che cos’è consumatori.it?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.content}>
            E’ la piattaforma di assistenza dell’Unione Nazionale Consumatori,
            prima storica associazione in Italia: siamo un’organizzazione senza
            fini di lucro che ha come compito statutario esclusivo la
            salvaguardia e la difesa degli interessi dei consumatori. Le
            Associazioni più importanti (come la nostra) sono riconosciute dal
            Codice del Consumo: la legge ci impone il rispetto di specifici
            requisiti (verificati ogni anno dal Ministero dello Sviluppo
            Economico).
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Per quali casi posso richiedere la vostra assistenza?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.content}>
            Il servizio di assistenza di consumatori.it riguarda tutte le
            controversie di consumo, cioè quelle tra un privato e un’azienda
            fornitrice di beni o servizi: dai problemi con la bolletta
            telefonica, abbonamento internet, utenze di luce, acqua e gas. Ma
            anche problemi con gli acquisti (sia nei negozi tradizionali che
            online), prodotti difettosi, acquisto dell’auto usata, autonoleggio,
            contenziosi con artigiani, multe stradali, banche, etc. Non ci
            occupiamo, invece, di questioni penali, di diritto di famiglia,
            condominio, fisco e tasse.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Che tipo di assistenza fornisce consumatori.it?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.content}>
            Riceviamo migliaia di reclami ogni settimana che vengono smistati ai
            nostri esperti che contattano il consumatore per avere ulteriori
            informazioni. Una volta valutato il caso, se possiamo esserti di
            aiuto, ti verrà proposto di iscriverti (50 euro o 100 euro a seconda
            dei servizi). Il consumatore valuterà se accettare o meno. In caso
            di iscrizione i nostri esperti possono supportarti dandoti i
            consigli per gestire al meglio il rapporto con l'azienda. Oppure (se
            preferisci) possiamo gestire integralmente la pratica per tuo conto.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Perché l’assistenza di consumatori.it è così efficace?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.content}>
            Perché con oltre 65 anni di esperienza sappiamo rintracciare le
            soluzioni più efficaci. E non è tutto: grazie ai canali
            preferenziali che abbiamo attivato negli anni con le principali
            aziende italiane, siamo in grado di ricevere una risposta con tempi
            molto più rapidi di quanto non potrebbe fare il consumatore da solo.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Cosa succede se la soluzione proposta dall'azienda dopo il vostro
            intervento non è di mio gradimento?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.content}>
            I nostri tassi di soddisfazione per determinate problematiche
            superano il 90 per cento: ciò significa che soluzione proposta
            dall'azienda è generalmente utile al consumatore. Tuttavia, qualora
            così non fosse il nostro socio resta libero di avviare un
            contenzioso con il supporto dei nostri legali (in questo caso
            l'onorario viene quotato a parte) oppure rivolgendosi a un suo
            legale di fiducia.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Non sono iscritto, posso richiedere assistenza?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.content}>
            Sì: i nostri esperti sono a disposizione anche per i non associati.
            Sarà nostro compito verificare la tua segnalazione e solo nel caso
            in cui potremo esserti di aiuto, verrà proposta l’iscrizione. A quel
            punto l’utente resta libero di accettare o meno. IMPORTANTE: se sei
            già un nostro iscritto, è consigliabile indicarlo chiaramente nella
            propria comunicazione (a ricevuta del versamento della quota vale
            come tessera di iscrizione).
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Devo fornire dei documenti per essere consigliato per il meglio?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.content}>
            No: in una prima fase sarà sufficiente compilare il form,
            descrivendo il tuo reclamo in modo chiaro e dettagliato, spiegando
            anche le tue richieste. Solo in un secondo momento i nostri esperti,
            se necessario, provvederanno a richiederti di inviare l’eventuale
            documentazione a sostegno della pretesa (contratti sottoscritti,
            contestazioni, diffide, foto, etc.)
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Posso richiedere che sia scritta una lettera per mio conto?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.content}>
            Sì certamente: se il consumatore non ha voglia di gestire in proprio
            la controversia, possono provvedere direttamente i nostri esperti:
            la redazione di una lettera di intervento è riservata agli iscritti
            “premium” (100 euro).
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Posso essere assistito in giudizio?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.content}>
            Sì: i nostri iscritti possono avvalersi dei legali fiduciari di
            consumatori.it per avviare un processo, ma in questo caso
            l’assistenza è soggetta ai costi che dovranno essere quantificati
            con il legale di riferimento, secondo le tariffe forensi.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Se ho già un avvocato posso rivolgermi a voi?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.content}>
            No: preferiamo evitare che il consumatore che ha già coinvolto un
            proprio legale di fiducia venga seguito dai nostri esperti. Del
            resto, se sei già assistito da un legale di tua fiducia, è
            preferibile non richiedere altri pareri per evitare un conflitto di
            interessi e pregiudicare l’iniziativa già avviata.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Perché iscriversi a consumatori.it?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.content}>
            Per semplificarti la vita! Attualmente oltre 63mila persone hanno
            deciso di diventare nostri soci (e sono quasi 300mila gli iscritti
            alla newsletter). È solo con il versamento della quota associativa
            che possiamo assisterti al meglio, incaricandoci di gestire la
            pratica con la controparte. Inoltre, l’iscritto potrà essere
            assistito nelle procedure di conciliazione per i reclami di
            telefonia, energia, ecommerce (per le controversie bancarie, qualora
            sia necessario rivolgersi all’Arbitro, potrebbe essere richiesta una
            integrazione della quota).
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Come posso iscrivermi?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.content}>
            Il versamento della quota di iscrizione (Euro 50,00 / Euro 100 a
            seconda dei servizi richiesti) può essere effettuata: • sul nostro
            sito tramite carta di credito oppure Paypal • mediante pagamento con
            bollettino postale, bonifico bancario • presso la nostra sede di
            Roma - via Duilio 13 (previo appuntamento)
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}
