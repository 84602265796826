import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  const isNarrow = window.innerWidth < 600;
  const modalHRatio = isNarrow ? 0.1 : 0.16;
  const modalVRatio = isNarrow ? 0.1 : 0.16;

  return {
    close: {
      color: "white",
      right: 12,
      width: 32,
      border: "none",
      height: 32,
      position: "absolute",
      fontSize: 22,
      textAlign: "center",
      fontWeight: 700,
      lineHeight: 1.5,
      borderRadius: 30,
      top: 5,
      background: "#19b063",
      cursor: "pointer",
    },
    modalContent: {
      position: "absolute",
      top: Math.ceil(window.innerHeight * modalHRatio),
      left: Math.ceil(window.innerWidth * modalVRatio),
      width: Math.ceil(window.innerWidth * (1 - modalHRatio * 2)),
      height: Math.ceil(window.innerHeight * (1 - modalHRatio * 2)),
      backgroundColor: "white",
      borderRadius: 20,
      paddingTop: 20,
    },
  };
});

export default function ModalShare({ handleClose }) {
  const classes = useStyles();

  return (
    <Container className={classes.modalContent}>
      <Typography variant="h2" style={{ marginBottom: 20 }}>
        Vuoi pubblicare la segnalazione sulla bacheca?
      </Typography>
      <Typography variant="body1" style={{ lineHeight: 1.5 }}>
        Se accetti, potrai:
        <ul>
          <li>fare ulteriore pressione all'azienda.</li>
          <li>aiutare altri nella tua stessa situazione</li>
          <li>essere invitato in TV o radio per parlarne</li>
        </ul>
      </Typography>
      <div className={classes.close} onClick={handleClose}>
        <span>X</span>
      </div>
    </Container>
  );
}
