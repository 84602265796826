import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    nome: {
      color: "white",
      fontWeight: "bold",
      fontStyle: "italic",
    },
    fonte: {
      fontStyle: "italic",
      color: "#d4fbe6",
      fontWeight: 100,
      fontSize: 16,
    },
    azienda: {
      color: "white",
    },
    testo: {
      color: "white",
      textAlign: "center",
    },
  };
});

export default function SliderItem({ nome, testo, fonte }) {
  const classes = useStyles();

  return (
    <Container disableGutters>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.nome}>
            {nome}
          </Typography>
          {fonte && <Typography className={classes.fonte}>{fonte}</Typography>}
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Typography variant="body1" className={classes.testo}>
            {testo}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
