import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles (theme => {
  const isNarrow = window.innerWidth < 600;
  const modalHRatio = isNarrow ? 0.1 : 0.16;
  const modalVRatio = isNarrow ? 0.1 : 0.16;

  let _rect;

  try {
    _rect = document
      .querySelector ('.ricerca-reclami.has-popup-alert.wrap-bradius-bshadow')
      .getBoundingClientRect ();
  } catch (e) {
    _rect = {top: 0, right: 0};
  }
  const _h = 310;
  const _w = 345;

  const _top = _rect.top + 150;
  let _left;

  if (window.innerWidth - _rect.right < 200) {
    _left = _rect.left - _w - 5;
  } else {
    _left = _rect.right + 5;
  }

  return {
    close: {
      color: 'white',
      right: 12,
      width: 32,
      border: 'none',
      height: 32,
      position: 'absolute',
      fontSize: 22,
      textAlign: 'center',
      fontWeight: 700,
      lineHeight: 1.5,
      borderRadius: 30,
      top: 5,
      background: '#19b063',
      cursor: 'pointer',
    },
    modalContent: {
      position: 'absolute',
      top: _top,
      left: _left,
      width: _w,
      height: _h,
      backgroundColor: '#094a7b',
      color: 'white',
      borderRadius: 16,
      paddingTop: 25,
    },
  };
});

export default function ModalReclamo({handleClose}) {
  const classes = useStyles ();
  let _rect;
  let _popupClass;

  try {
    _rect = document
      .querySelector ('.ricerca-reclami.has-popup-alert.wrap-bradius-bshadow')
      .getBoundingClientRect ();
  } catch (e) {
    return '';
  }

  if (window.innerWidth - _rect.right < 200) {
    _popupClass = 'popup-left';
  } else {
    _popupClass = 'popup-right';
  }

  return (
    <Container className={`${classes.modalContent} ${_popupClass}`}>
      <Typography variant="body1" style={{lineHeight: 1.5}}>
        <span>La tua segnalazione avrà maggior successo se comprende:</span>
        <ul>
          <li>Informazioni sulle date.</li>
          <li>
            Chiarimenti sul canale utilizzato (punto vendita, online ecc...).
            {' '}
          </li>
          <li>
            Cosa hai fatto finora (inviato un reclamo, contattato un avvocato).
          </li>
          <li>L'azienda ti ha già risposto?</li>
          <li>
            Ricorda di non inserire in questo campo i tuoi dati personali per evitare che tutti possano vederli
          </li>
        </ul>
      </Typography>
      <div className={classes.close} onClick={handleClose}>
        <span>X</span>
      </div>
    </Container>
  );
}
