import Fuse from 'fuse.js';

function escapeRegexCharacters (str) {
  return str.replace (/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export default function superSearch (data, value) {
  if (value.length < 2) {
    return [];
  }

  const escapedValue = escapeRegexCharacters (value.trim ());

  if (escapedValue === '') {
    return [];
  }

  const options = {
    includeScore: true,
    minMatchCharLength: 2,
    shouldSort: true,
    threshold: 0.1,
    keys: [{name: 'az', wwight: 0.7}, {name: 'se', wwight: 0.3}],
  };

  const fuse = new Fuse (data.aziende, options);

  const result = fuse.search (escapedValue).sort (function (item1, item2) {
    const _score1 = item1.score * item1.item.wt;
    const _score2 = item2.score * item2.item.wt;
    return _score2 - _score1;
  });

  //console.log(result);

  return [...result];
}
