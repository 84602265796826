import React, {useEffect} from 'react';
import Layout from '../commons/Layout';
import Head from '../commons/Head';
import Message from './Message';
import Share from './Share';
import useGlobal from '../../store';

export default function Thankyou () {
  return (
    <div className="uic-ty__container">
      <Message />
      <Share specialClass="greyTest" />
    </div>
  );
}
