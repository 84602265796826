import React from 'react';
import globalHook from 'use-global-hook';

import * as actions from '../actions';

const initialState = {
  idAzienda: null,
  idChatbot: null,
  campaign: null,
  medium: null,
  type: 'null',
  dataLoading: false,
  data: [],
  step: 1,
  formStep: 1,
  branch: '',
  results: [],
  searchInput: '',
  idReclamo: false,
  formData: {},
  comitato: 'you-tool',
};

const useGlobal = globalHook (React, initialState, actions);

export default useGlobal;
