import React from "react";
import Slick from "react-slick";
import SliderItem from "./SliderItem";
import useGlobal from "../../store";

export default function Slider() {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();

  return (
    <React.Fragment>
      {globalState.data.settings && (
        <Slick {...globalState.data.settings.slider}>
          {globalState.data.recensioni.map((rec, i) => (
            <div>
              <SliderItem
                key={i}
                nome={rec.nome}
                testo={rec.testo}
                fonte={rec.fonte}
              />
            </div>
          ))}
        </Slick>
      )}
    </React.Fragment>
  );
}
