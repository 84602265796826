import React, {useEffect, useState} from 'react';
import Layout from '../commons/Layout';
import Head from '../commons/Head';
import FormHeader from './Header';
import Input from './Input';
import useGlobal from '../../store';
import Thankyou from '../thankyou/Thankyou';

export default function Form({
  idAzienda,
  isCategory,
  isChatbot,
  campaign,
  medium,
  source,
}) {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal ();

  useEffect (() => {
    globalActions.setIdAzienda (idAzienda);
    globalActions.setIsCategory (isCategory);
    globalActions.setIsChatbot (isChatbot);
    globalActions.setCampaign (campaign);
    globalActions.setMedium (medium);
    globalActions.setSource (source);
    globalActions.getData ();
  }, []);

  const [showThanks, setShowThanks] = useState (0);

  return (
    <div>
      <React.Fragment>
        {0 === showThanks &&
          <Input
            padding={0}
            onSuccess={() => setShowThanks (1)}
            onError={() => setShowThanks (1)}
          />}

        {1 === showThanks && <Thankyou />}

      </React.Fragment>
    </div>
  );
}
