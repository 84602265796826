import React, { useEffect } from "react";
import Layout from "../commons/Layout";
import Head from "../commons/Head";
import Choice from "./Choice";
import Bottom from "./Bottom";
import HowItWorks from "./HowItWorks";
import Testimonials from "../commons/Testimonials";
import useGlobal from "../../store";
import Slider from "../gate/Slider";

export default function Gate() {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();

  useEffect(() => {
    globalActions.setSearchInput("");
    // eslint-disable-next-line
  }, [globalState.step]);

  return (
    <Layout>
      <Head specialClass="greyGreen" />
      <Choice />
      <Bottom />
      <HowItWorks padding="10px 10px 50px 10px" />
      <Testimonials
        specialClass="greyGreen"
        image="https://goat.keyformat.it/static/uip/media/happyman-celphone.png"
        title="Dicono di noi"
        slider={<Slider />}
      />
    </Layout>
  );
}
