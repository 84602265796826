import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

export default function Testimonials({ image, title, slider }) {
  return (
    <Container style={{ marginTop: -74 }} maxWidth="sm">
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item xs={12}>
          <img alt="" src={image} />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Typography variant="h3" style={{ color: "white" }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20, paddingBottom: 100 }}>
          {slider}
        </Grid>
      </Grid>
    </Container>
  );
}
