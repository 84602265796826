import React, {useEffect} from 'react';
import Layout from '../commons/Layout';
import Head from '../commons/Head';
import Search from './Search';
import Testimonials from '../commons/Testimonials';
import useGlobal from '../../store';
import Slider from './Slider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const Intro = () => (
  <React.Fragment>
    <Grid item xs={12}>
      <Typography style={{textAlign: 'center'}} variant="h2">
        Hai un problema da risolvere?
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography style={{textAlign: 'center'}} variant="body1">
        Sei vittima di un disservizio da parte di un'azienda e non sai come risolverlo?
        <br />
        Compila in pochi passi il tuo reclamo, al resto pensiamo noi.
      </Typography>
    </Grid>
  </React.Fragment>
);

export default function Aziende({type}) {
  return (
    <React.Fragment>
      {type !== 'search-mini' && <Intro />}
      <Search type={type} />
    </React.Fragment>
  );
}
