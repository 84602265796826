import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    nome: {
      color: "white",
      fontSize: 22,
      fontWeight: "bold",
    },
    azienda: {
      color: "white",
    },
    testo: {
      color: "white",
      textAlign: "center",
    },
  };
});

export default function SliderItem({ nome, azienda, testo }) {
  const classes = useStyles();

  return (
    <Container disableGutters>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item xs={12}>
          <Typography className={classes.nome}>
            {nome} / {azienda}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 30 }}>
          <Typography variant="body1" className={classes.testo}>
            {testo}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
