import React from 'react';
import Grid from '@material-ui/core/Grid';
import Item from '../commons/Item';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import useGlobal from '../../store';

const getItemsToShow = function _getItems (data) {
  const _base = data.categorie.filter (
    ({id}) => id !== 7 && id !== 87 && id !== 86
  );
  const _generico = data.categorie.filter (({id}) => id === 7);
  return [..._base, ..._generico];
};

export default function Settori({type}) {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal ();

  const mostSearchedItem = (catId, i) => {
    let _cat = globalState.data.categorie.filter (({id}) => catId === id)[0];
    if (!_cat) {
      console.log ('+++++++++++'._cat, globalState.data.aziende);
      _cat = globalState.data.aziende.filter (({id}) => catId === id)[0];
    }

    return <Item renderAsChip azienda={_cat} key={i} />;
  };

  return (
    <Container maxWidth="md" disableGutters>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h2">...non trovi l'azienda?</Typography>
          {type === 'search-mini' &&
            <Typography variant="h3">
              <a href="/reclami/bacheca/">consulta la bacheca reclami.</a>
            </Typography>}
        </Grid>
        {type !== 'search-mini' &&
          <Grid item xs={12} style={{marginBottom: 20}}>
            <Typography variant="body1">
              <i>
                indicaci il settore di riferimento
                <br />o utilizza lo Sportello Generico
              </i>
            </Typography>
          </Grid>}
        {type !== 'search-mini' &&
          <Grid item xs={12} style={{marginBottom: 20, textAlign: 'center'}}>
            {globalState.data.categorie &&
              getItemsToShow (globalState.data, 16).map ((set, i) => {
                const obj = Object.assign ({}, set);
                return <Item azienda={obj} key={i} renderAsChip />;
              })}
          </Grid>}
        {type !== 'search-mini' &&
          <Grid item xs={12} style={{marginTop: 10}}>
            <Typography variant="body2">Campagne in evidenza</Typography>
          </Grid>}
        {type !== 'search-mini' &&
          <Grid item xs={12} style={{paddingBottom: 110}}>
            {globalState.data &&
              globalState.data.most_active_campaigns &&
              globalState.data.most_active_campaigns.map (mostSearchedItem)}
          </Grid>}
      </Grid>
    </Container>
  );
}
