import React from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AnchorLink from "react-anchor-link-smooth-scroll";
import useGlobal from "../../store";

export default function Choice() {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();

  function gotoAziende() {
    globalActions.setStep(2);
  }

  return (
    <Container
      maxWidth="sm"
      className="gate__container"
      disableGutters
      style={{ marginBottom: 20 }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Typography variant="body1" style={{ fontSize: 16 }}>
            In pochi passi puoi compilare il tuo reclamo,
            <br /> i nostri esperti ti diranno
            <br /> come risolvere il problema.
            <br />
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{ maxWidth: 450 }}
            onClick={gotoAziende}
            /*
            endIcon={
              <ArrowForwardIcon
                style={{ fontSize: 30, position: "relative", right: -40 }}
              />
            }
            */
          >
            INIZIA SUBITO!
          </Button>
        </Grid>

        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={8}>
            <AnchorLink href="#howitworks">
              <Typography
                variant="body1"
                style={{ paddingBottom: 8, display: "inline-flex" }}
              >
                <span style={{ lineHeight: "200%", paddingRight: 10 }}>
                  Scopri come funziona
                </span>
                <img
                  src="https://goat.keyformat.it/static/uip/media/qm-1.png"
                  alt=""
                />
              </Typography>
            </AnchorLink>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
