import './App.css';
import React, {useEffect} from 'react';
import Aziende from './components/aziende/Aziende';
import Gate from './components/gate/Gate';
import Form from './components/form/Form';
import Thankyou from './components/thankyou/Thankyou';
import {ThemeProvider} from '@material-ui/core';
import useGlobal from './store';
import theme from './theme';

/*
window.onbeforeunload = function () {
  return "Are you sure you want to leave?";
};
*/

export default function App({
  step,
  type,
  idAzienda,
  isCategory,
  isChatbot,
  campaign,
  medium,
  source,
}) {
  console.log ('*****', step, type, idAzienda, isCategory);

  return (
    <ThemeProvider theme={theme}>
      {1 === step &&
        <Gate
          idAzienda={idAzienda}
          isCategory={isCategory}
          type={type}
          isChatbot={isChatbot}
          campaign={campaign}
          medium={medium}
          source={source}
        />}
      {2 === step &&
        <Aziende
          idAzienda={idAzienda}
          isCategory={isCategory}
          type={type}
          isChatbot={isChatbot}
          campaign={campaign}
          medium={medium}
          source={source}
        />}
      {3 === step &&
        <Form
          idAzienda={idAzienda}
          isCategory={isCategory}
          type={type}
          isChatbot={isChatbot}
          campaign={campaign}
          medium={medium}
          source={source}
        />}
    </ThemeProvider>
  );
}
