import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

export default function Chiamaci() {
  return (
    <Grid container spacing={2} style={{ paddingBottom: 30 }}>
      <Grid item xs={12}>
        <Typography variant="body1">Se preferisci, chiamaci</Typography>
      </Grid>
      <Grid item xs={12}>
        <a href="tel:0632600239" className="link-tel__link">
          <Container
            className="link-tel__container"
            maxWidth="sm"
            style={{ maxWidth: 370 }}
          >
            <Grid container spacing={0}>
              <Grid item xs={2}></Grid>
              <Grid
                item
                xs={2}
                style={{ marginTop: 6, paddingRight: 10, textAlign: "center" }}
              >
                <img
                  alt=""
                  src="https://goat.keyformat.it/static/uip/media/cornetta-1.png"
                />
              </Grid>
              <Grid
                item
                xs={6}
                style={{ paddingLeft: 10, textAlign: "center" }}
              >
                <span className="tel-number">06 32.600.239</span>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </Container>
        </a>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          dal Lunedì al Venerdì <br /> ore 9 - 13 / 14 - 18
        </Typography>
      </Grid>
    </Grid>
  );
}
