import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Item from './Item';
import Chip from '@material-ui/core/Chip';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import useGlobal from '../../store';

const useStyles = makeStyles (theme => {
  return {
    root: {
      backgroundColor: theme.palette.greyBg.main,
      margin: 0,
    },
    chip: {
      backgroundColor: 'white',
      borderRadius: 20,
      height: 30,
      fontSize: 16,
      fontWeight: 600,
      color: '#666',
      padding: '5px 20px',
      border: '1px solid grey',
    },
  };
});

const getItemsToShow = function _getItems (data, numItems) {
  if (!data || !data.aziende) {
    return [];
  }
  const tops = data.aziende
    .filter (obj => 1 === obj.tp)
    .sort ((a, b) => b.wt - a.wt);

  const others = data.aziende
    .filter (o => 0 === o.tp)
    .sort ((a, b) => b.wt - a.wt);

  const result = tops.length >= numItems
    ? tops.slice (0, numItems)
    : [...tops, ...others.slice (0, numItems - tops.length)];

  return result;
};

export default function MostSearched({maxItems, extraspace}) {
  const classes = useStyles ();
  const [numItems, setNumItems] = useState (maxItems);
  const bottomSpace = 30 + extraspace;
  const [globalState, globalActions] = useGlobal ();

  useEffect (
    () => {
      globalActions.getData ();
      // eslint-disable-next-line
    },
    [globalState.data.length]
  );

  const mostSearchedItem = (catId, i) => {
    let _cat = globalState.data.categorie.filter (({id}) => catId === id)[0];
    if (!_cat) {
      console.log ('+++++++++++'._cat, globalState.data.aziende);
      _cat = globalState.data.aziende.filter (({id}) => catId === id)[0];
    }

    return <Item renderAsChip azienda={_cat} key={i} />;
  };

  return (
    <Container maxWidth="md" className="uic-mostsearched__container">
      {true === globalState.dataLoading && <div>LOADING</div>}
      <Grid
        container
        spacing={3}
        style={{paddingTop: 10, margin: '0!important'}}
        className={classes.root}
      >
        <Grid item xs={12}>
          <Typography variant="h2" className="MuiUppercaseInterLabel">
            Campagne in evidenza
          </Typography>
        </Grid>
        <Grid item xs={12} style={{marginBottom: 20, textAlign: 'center'}}>
          {globalState.data &&
            globalState.data.most_active_campaigns &&
            globalState.data.most_active_campaigns.map (mostSearchedItem)}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" className="MuiUppercaseInterLabel">
            Reclami frequenti
          </Typography>
        </Grid>
        <Grid item xs={12} style={{marginBottom: 20, textAlign: 'center'}}>
          {globalState.data &&
            getItemsToShow (globalState.data, numItems).map ((res, i) => {
              return <Item renderAsChip azienda={res} key={i} />;
            })}
        </Grid>
      </Grid>
    </Container>
  );
}
