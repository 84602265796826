import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import useGlobal from '../../store';
import sanitize from 'string-sanitizer';

const useStyles = makeStyles (theme => ({
  title: {
    color: 'white',
    fontSize: 23,
    padding: '10px 0 10px',
  },
  text: {
    color: 'white',
  },
  btnShare: {
    marginTop: 20,
    backgroundColor: 'white',
    color: `${theme.palette.primary.main}!important`,
    fontSize: 21,
    fontWeight: 600,
    textTransform: 'none',
  },
  shares: {
    margin: 20,
  },
}));

function getURL (reclamo, slug, idReclamo) {
  const host = new URL (window.location.href).hostname;
  const protocol = new URL (window.location.href).protocol;

  const _reclamo = sanitize.sanitize
    .addDash (reclamo)
    .split ('-')
    .slice (0, 12)
    .join ('-');

  const url = `${protocol}//${host}/reclami/bacheca/${slug}/${_reclamo}-${idReclamo}`;
  console.log (url);
  return url;
}

export default function Message () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal ();

  const reclamo = globalState.formData.reclamo;
  /*
  const azSlug = globalState.data.aziende.filter (
    az => az.id === globalState.idAzienda
  )[0].slug;
  */

  const azSlug = globalState.azienda.slug || globalState.azienda.az;
  const idReclamo = globalState.idReclamo;

  // console.log (globalState);
  // console.log (reclamo, azSlug, idReclamo);
  // console.log (sanitize.sanitize.addDash (reclamo));

  const classes = useStyles ();
  const urlToShare = getURL (reclamo, azSlug, idReclamo);
  console.log (urlToShare);

  const fbShareURL = `https://www.facebook.com/share.php?u=${urlToShare}`;
  const twShareURL = `https://twitter.com/intent/tweet?url=${urlToShare}`;
  const liShareURL = `https://www.linkedin.com/shareArticle?mini=true&url=${urlToShare}`;

  return (
    <Grid container direction="column" style={{textAlign: 'center'}}>
      <Grid item xs={12} style={{marginTop: 10}}>
        <Typography variant="h3" className={classes.title}>
          Condividi la tua esperienza sui social.
        </Typography>
      </Grid>
      <Grid item xs={12} style={{margin: '10px 0'}}>
        <Typography variant="body2" className={classes.text}>
          A volte è sufficiente condividere il tuo reclamo: fai sapere che ti
          stiamo aiutando a risolvere il tuo problema.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ul class="social-icon header">
          <li>
            <a href={fbShareURL} target="_blank">
              <span className="unc-icon icon-facebook-midgreen" />
            </a>
          </li>
          <li>
            <a href={twShareURL} target="_blank">
              <span className="unc-icon icon-twitter-midgreen" />
            </a>
          </li>
          <li>
            <a href={liShareURL} target="_blank">
              <span className="unc-icon icon-linkedin-midgreen" />
            </a>
          </li>
        </ul>
      </Grid>
    </Grid>
  );
}
