import React from 'react';
import Chiamaci from '../static/Chiamaci';
import MostSearched from '../commons/MostSearched';

export default function Bottom () {
  const items = window.innerWidth > 500 ? 12 : 6;

  return (
    <React.Fragment>
      <MostSearched maxItems={items} extraspace={0} />
      <Chiamaci />
    </React.Fragment>
  );
}
