import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import useGlobal from "../../store";

export default function HowItWorks() {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();

  function gotoAziende() {
    globalActions.setStep(2);
  }

  return (
    <Container maxWidth="sm" disableGutters style={{ marginTop: -10 }}>
      <section className="kf-sec" id="howitworks">
        <div className="kf-cont">
          <div className="kf-row kf-head">
            <h2 className="kf-h2">Come funziona?</h2>
          </div>

          <div className="kf-row kf-num-left">
            <div className="num">1</div>
            <div className="cont">
              Seleziona l'azienda o la categoria del reclamo che ti riguarda
            </div>
          </div>

          <div className="kf-row kf-num-right">
            <div className="num">2</div>
            <div className="cont">
              La segnalazione sarà valutata dai nostri esperti
            </div>
          </div>

          <div className="kf-row kf-num-left">
            <div className="num">3</div>
            <div className="cont">
              Sarai contattato per risolvere il tuo problema.
            </div>
          </div>

          <div className="kf-row kf-num-right row-4">
            <div className="num">4</div>
            <div className="cont">
              Ti proporremo un'iscrizione, ma sarai sempre tu a decidere.
            </div>
          </div>

          <div className="kf-row kf-num-left">
            <div className="num">5</div>
            <div className="cont">
              Seguiremo noi la tua pratica e tu potrai dormire sonni tranquilli!
            </div>
          </div>
        </div>
      </section>

      <Typography variant="body1" style={{ marginTop: -290 }}>
        Cosa aspetti?
      </Typography>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        style={{ marginBottom: 80, marginTop: 10, maxWidth: 450 }}
        onClick={gotoAziende}
      >
        INIZIA SUBITO!
      </Button>
    </Container>
  );
}
